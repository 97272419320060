<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Valor total de la compañía </span>
    </div>

    <div class="upper-global-hint" id="hint-container">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>

    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="initial-dasboard-main-container">
      <div class="initial-dashboard-title-container">
        <h3 class="form-division">Resumen</h3>
      </div>
      <div class="company-value-main-container">
        <div
          class="company-value-half-graph global-border-radius global-border-shadow"
        >
          <apexchart
            width="100%"
            type="donut"
            :options="companyValueChart.options"
            :series="companyValueChart.series"
          ></apexchart>
        </div>
        <div class="company-value-cards-container">
          <InformationCard
            name="Valor total del inventario"
            :value="companyTotalValue.inventory"
            format="moneyOnly"
            width="full"
            :moreDetails="false"
          />

          <InformationCard
            name="Total en cuentas por cobrar"
            :value="companyTotalValue.incomes"
            format="moneyOnly"
            width="full"
            :moreDetails="false"
          />

          <InformationCard
            name="Total en cuentas por pagar"
            :value="companyTotalValue.expenses"
            format="moneyOnly"
            width="full"
            :moreDetails="false"
          />

          <InformationCard
            name="Total en cuentas bancarias"
            :value="companyTotalValue.banks"
            format="moneyOnly"
            width="full"
            :moreDetails="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "./InformationCard";
// @ is an alias to /src
export default {
  name: "CompanyValue",
  components: {
    InformationCard,
  },
  data() {
    return {
      moment: moment,
      errors: {},
      companyValueChart: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Valor total de la compañía",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
              margingBottom: "1.5rem",
            },
          },
          labels: [
            "Valor total del inventario",
            "Total en cuentas por cobrar",
            "Total en cuentas por pagar",
            "Total en cuentas bancarias",
            "Total en activos",
          ],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  size: "100%",
                  total: {
                    show: true,
                    label: "Total",
                    color: "#373d3f",
                    formatter: (w) => {
                      let currentTotal = 0;
                      for (const [
                        singleSerieIndex,
                        singleSerie,
                      ] of w.globals.seriesTotals.entries()) {
                        if (
                          w.globals.seriesNames[singleSerieIndex] ===
                          "Total en cuentas por pagar"
                        ) {
                          currentTotal -= singleSerie;
                        } else {
                          currentTotal += singleSerie;
                        }
                      }
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  height: "800px",
                  width: "100%",
                },
              },
            },
          ],
        },
        series: [0, 0, 0, 0],
      },
    };
  },
  created() {
    moment.locale("es");
  },
  async mounted() {
    this.$store.dispatch("CHANGEACTIVE", "value");

    let allPromises = [];
    allPromises.push(this.$store.dispatch("GETCOMPANYTOTALVALUE"));
    await Promise.all(allPromises);

    this.updateSeries();
  },
  methods: {
    updateSeries() {
      this.companyValueChart.options = {
        ...this.companyValueChart.options,
        labels: [
          "Valor total del inventario",
          "Total en cuentas por cobrar",
          "Total en cuentas por pagar",
          "Total en cuentas bancarias",
          "Total en activos",
        ],
      };
      this.companyValueChart.series = [
        this.companyTotalValue.inventory ? this.companyTotalValue.inventory : 0,
        this.companyTotalValue.incomes ? this.companyTotalValue.incomes : 0,
        this.companyTotalValue.expenses ? this.companyTotalValue.expenses : 0,
        this.companyTotalValue.banks ? this.companyTotalValue.banks : 0,
        this.companyTotalValue.assets ? this.companyTotalValue.assets : 0,
      ];
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyTotalValue() {
      return this.$store.getters.COMPANYTOTALVALUE;
    },
  },
  watch: {
    companyTotalValue() {
      this.updateSeries();
    },
  },
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
@import "../../Global/style/InitialDashboard.css";
@import "../../Global/style/CompanyValue.css";
</style>
